<template src="./UniversityResearchGroups.html"></template>

<script>
import ArrobaMedellinSeeker from "@/components/ArrobaMedellinSeeker/ArrobaMedellinSeeker";
import idiService from "../../services/Idi";
import ResearchGroupsVerticalCard from "../../components/ResearchGroupsVerticalCard/ResearchGroupsVerticalCard";
import ArrobaMedellinArrobitaNoData from "../../components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData";

export default {
  name: "universityResearchGroups",
  components: {
    ArrobaMedellinSeeker,
    ResearchGroupsVerticalCard,
    ArrobaMedellinArrobitaNoData,
  },
  data() {
    return {
      errorMessage: false,
      countGroups: {},
      allResearchGroups: {},
    };
  },
  mounted() {
    this.getResearchGroups();
  },
  created() {
    this.institutionId = this.$store.state.User.user.institutionId.id;
  },
  methods: {
    async getResearchGroups() {
      try {
        this.myResearchGroups = await idiService.myGroups();
        this.allResearchGroups = await idiService.researchGroupsByInstitution({
          institutionId: this.institutionId,
          take: 50,
          skip: 0,
        });
        this.countGroups = await idiService.countGroups(this.institutionId);
      } catch (error) {
        console.error(error);
      }
    },
    async getSearchGroups(word) {
      try {
        this.errorMessage = false;
        if (!word) {
          await this.getResearchGroups();
        } else {
          const response = await idiService.searchGroupsByInstitution({
            institutionId: this.institutionId,
            word: word,
            take: 5,
            skip: 0,
          });
          if (response.length === 0) this.errorMessage = true;
          this.allResearchGroups = response;
        }
      } catch (error) {
        console.error("Error getResearchGroups", error);
      }
    },
    redirectTo(path, id) {
      this.$router.push({ name: path, params: { id: id } });
    },
  },
};
</script>

<style lang="scss" scoped src="./UniversityResearchGroups.scss"></style>